export class ResponseError<T extends unknown> extends Error {
  info: T | null = null
  status: Response['status'] | null = null
}
export const DEFAULT_SWR_OPTIONS = { shouldRetryOnError: false }

/**
 *
 * fetcher is used for SWR and only for GET Methods
 *
 * SWR will cache GET request
 *
 * The name “SWR” is derived from stale-while-revalidate, a HTTP cache invalidation strategy popularized by HTTP RFC 5861.
 * SWR is a strategy to first return the data from cache (stale), then send the fetch request (revalidate), and finally
 * come with the up-to-date data.
 *
 * see https://swr.vercel.app/ for more information
 *
 * @param legacy boolean
 * @returns
 */
export function fetcher(legacy = true, endpoint: string | null = null) {
  return async function (input: RequestInfo, init?: RequestInit | undefined) {
    const origin =
      process.env.NODE_ENV === 'test' ? 'https://eversign.localhost' : window.location.origin
    // Why is this not correct set in localdev ?
    // its false when not set
    let API_ENDPOINT = 'https://eversign-api.mgm.apilayer.localhost'

    if (process.env.NODE_ENV !== 'test') {
      API_ENDPOINT = window.EVERSIGN_CONFIG.EVERSIGN_API_URL
        ? window.EVERSIGN_CONFIG.EVERSIGN_API_URL
        : 'https://eversign-api.mgm.apilayer.localhost'
    }

    let ENDPOINT = legacy ? origin : API_ENDPOINT
    if (endpoint) {
      ENDPOINT = endpoint
    }

    const headers: RequestInit['headers'] = {}

    if (legacy) {
      const csrfToken =
        document?.querySelector("meta[name='Csrf-Token']")?.getAttribute('content') ??
        'no token given'
      headers['Csrf-Token'] = csrfToken
    }

    try {
      const response = await fetch(`${ENDPOINT}${input}`, {
        ...init,
        headers: {
          ...init?.headers,
          ...headers,
        },
      })

      if (!response.ok) {
        const error = new ResponseError('An error occurred while fetching the data.')
        error.info = await response.json()
        error.status = response.status
        throw error
      }
      const data = await response.json()

      if (legacy) {
        if (data.success) {
          return data
        } else if (input === '/php/ajax_handler.php?endpoint=setting&action_type=get') {
          //
          // new edgecase dont has the standard response model
          //
          return data
        } else {
          const error = new ResponseError('An error occurred while fetching the data.')
          console.error('Legacy Api Error')
          console.warn('please review error and update Error Response')
          error.info = await response.json()
          error.status = response.status
          throw error
        }
      }
      return data
    } catch (error: unknown) {
      //
      // in cypress some request get canceled.
      //
      // if (typeof error !== 'undefined') {
      //   throw error
      // }
      return
    }
  }
}

export const legacyFetcher = fetcher(true)
export const apiFetcher = fetcher(false)
