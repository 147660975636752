import {
  Link as ChakraLink,
  Flex,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useGetBusinessSettings } from '../../api/legacy/use-business-settings'
import {
  Box,
  EvrCompare,
  EvrCopy,
  EvrGroupWork,
  EvrPieChart,
  EvrSettings,
  EvrTiles,
  EvrTrash2,
  EvrUsers,
  Text,
} from '../../primitives'
import { DropdownMenu } from '../PageNotFound/Dropdown'

const GRAY_50 = 'var(--chakra-colors-gray-50)'
const NAVY_100 = 'var(--chakra-colors-navy-100)'

const MobileMenu = () => {
  const { isOpen, onToggle } = useDisclosure()
  const [isScrollDisabled, setIsScrollDisabled] = useState(false)

  const businessSettings = useGetBusinessSettings()

  const defaultTab = businessSettings.data?.document_list_default_tab

  const documentsUrl = defaultTab ? `/documents?filterQuery=${defaultTab}` : '/documents'

  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  const handleOpen = () => {
    document.body.style.overflow = 'hidden'
    setIsScrollDisabled(true)
  }

  const handleClose = () => {
    if (isOpen) {
      onToggle()
    }
  }

  const handleToggle = () => {
    onToggle()
    document.body.style.overflow = 'hidden'
    setIsScrollDisabled(true)
  }
  return (
    <Box>
      <Menu onOpen={handleOpen} onClose={handleClose}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<EvrTiles w={'20px'} h="20px" />}
          bg="white"
          _hover={{ bg: 'white' }}
          _active={{ bg: 'white' }}
          _focus={{ bg: 'white', boxShadow: 'none' }}
          position="relative"
          margin="10px !important"
          data-testid="app-header-mobile-menu"
        />
        <MenuList
          style={{ width: '100vw' }}
          borderRadius={'0px'}
          fontSize={'14px'}
          color="indigo.100"
          height={'100vh'}
          width="100%"
          bg={'navy.100'}
        >
          <DropdownMenu isOpen={isOpen} onToggle={handleToggle} />
          <Grid
            data-testid=" mobile-menu-list-container"
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            rowGap={2.5}
            columnGap={2}
            p="10px"
          >
            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/dashboard"
                href="/dashboard"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrPieChart w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Dashboard{' '}
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/dashboard"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrPieChart w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Dashboard{' '}
                </Text>
              </MenuItem>
            )}
            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to={documentsUrl}
                href={documentsUrl}
                height="74px"
                padding="15px"
                borderRadius="3px"
                bg={`${GRAY_50} !important`}
              >
                <Flex justify="center" direction="column" alignItems="center">
                  <EvrCopy w="20px" h="20px" mb="4px" />
                  <Text fontSize="sm" fontWeight="medium">
                    Documents
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius="3px"
                padding="15px"
                as="a"
                href={documentsUrl}
                height="74px"
                justifyContent="flex-end"
                flexDir="column"
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <Flex justify="center" direction="column" alignItems="center">
                  <EvrCopy w="20px" h="20px" mb="4px" />
                  <Text fontSize="sm" fontWeight="medium">
                    Documents
                  </Text>
                </Flex>
              </MenuItem>
            )}

            <MenuItem
              bg={`${GRAY_50} !important`}
              borderRadius={'3px'}
              padding="15px"
              as="a"
              href="/templates"
              height={'74px'}
              justifyContent="flex-end"
              flexDir={'column'}
              _active={{ bg: `${NAVY_100} !important` }}
            >
              <EvrCompare w={'20px'} h={'20px'} mb={'4px'} />
              <Text fontSize={'sm'} fontWeight="medium">
                Templates{' '}
              </Text>
            </MenuItem>

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/contacts"
                href="/contacts"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrGroupWork w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Contacts{' '}
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/contacts"
                justifyContent="flex-end"
                flexDir={'column'}
                height="74px"
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrGroupWork w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Contacts{' '}
                </Text>
              </MenuItem>
            )}

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/team"
                href="/team"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrUsers w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Team
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/team"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrUsers w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Team
                </Text>
              </MenuItem>
            )}

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/trash"
                href="/trash"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrTrash2 w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Trash
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/trash"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrTrash2 w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Trash
                </Text>
              </MenuItem>
            )}
            <MenuItem
              bg={`${GRAY_50} !important`}
              borderRadius={'3px'}
              padding="15px"
              as="a"
              href="/settings"
              height={'74px'}
              justifySelf={'center'}
              flexDir="column"
              gridColumn={'span 2'}
              maxW="50%"
              justifyContent="flex-end"
              _active={{ bg: `${NAVY_100} !important` }}
            >
              <EvrSettings w={'20px'} h={'20px'} mb={'4px'} />
              <Text fontSize={'sm'} fontWeight="medium">
                Business Settings{' '}
              </Text>
            </MenuItem>
          </Grid>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default MobileMenu
