import { useParams } from 'react-router-dom'
import { App } from '../../Provider/App.Provider'
import { Header, HeaderTitle } from '../../components/Layout/Header'
import Layout from '../../components/Layout/Layout'
import Page from '../../components/Layout/Page'
import { PageLayoutContent } from '../../primitives/PageLayout/PageLayoutContent'
import { Button, Card, CenterSpinner, EvrChevronLeft, Text } from '../../primitives'
import {
  DocumentDetail,
  useGetDocumentDetail,
} from '../Documents/hooks/get-document-details'
import { Webviewer } from '../../components/Webviewer'
import { Box, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { EvrFileAdd } from '../../primitives/Icons/EvrFileAdd'
import { HeaderDropdownClickItem, HeaderDropdownMenuItem } from '../../components/HeaderDropdownMenuItem'
import { useGetAuditTrailDocument } from '../Documents/hooks/get-audit-trail'

export const AuditTrail = ({ documentHash }: { documentHash: string }) => {
  const documentDetails = useGetDocumentDetail({ documentHash })
  const downloadUrl = useGetAuditTrailDocument({ documentHash })

  if (downloadUrl.isLoading || documentDetails.isLoading) {
    return <CenterSpinner />
  }

  if (downloadUrl.isError || documentDetails.isError) {
    return <span>error</span>
  }

  const documentData = documentDetails.data as DocumentDetail
  const isDocumentCompleted = documentData.document_status === 'completed'
  const documentTitle = documentData.document_title

  return (
    <Layout>
      <Page
        renderHeader={
          <Header
            renderTitle={<HeaderTitle>{documentTitle}</HeaderTitle>}
            renderRight={
              <Box position="relative" id="header-dropdown-container" display={['none', 'flex']}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="primary"
                    pl="5"
                    pr="5"
                    data-testid="document-header-toggle-quick-actions-dropdown-btn"
                  >
                    <Text fontSize={'sm'}>Quick Actions</Text>
                  </MenuButton>
                  <MenuList
                    p="0"
                    id="header-dropdown-menu-list"
                    minW={'154px'}
                    data-testid="header-dropdown-menu-list-container"
                    mt={10}
                  >
                    <HeaderDropdownMenuItem
                      href={`/document/${documentHash}/download/1/AT`}
                      testId="dashboard-header-new-document-link"
                    >
                      <EvrFileAdd />
                      <Text fontSize={'sm'} pl="2">
                        Download PDF
                      </Text>
                    </HeaderDropdownMenuItem>
                    <HeaderDropdownClickItem
                      onClick={() => window.print()}
                      testId="dashboard-header-new-document-link"
                    >
                      <EvrFileAdd />
                      <Text fontSize={'sm'} pl="2" cursor={'pointer'}>
                        Print
                      </Text>
                    </HeaderDropdownClickItem>
                  </MenuList>
                </Menu>
              </Box>
            }
            renderLeft={
              <Button
                testId={'back-to-document-btn'}
                variant={'outline'}
                _hover={{
                  borderColor: 'teal.100',
                }}
                onClick={() => {
                  window.location.assign(`/documents/${documentHash}`)
                }}
                px="5"
              >
                <EvrChevronLeft w="10px" mr="2"/>
                <Text fontSize={"sm"}>
                  Back to Document
                </Text>
              </Button>
            }
          />
        }
      >
        <input type="hidden" name="current_page" value="dashboard" />
        <PageLayoutContent>
          <Card borderRadius={'3px 3px 0 0'} renderHeader={null}>
            <Webviewer
              pdfURL={downloadUrl.data.url}
              hasSignaturePanel={isDocumentCompleted}
            />
          </Card>
        </PageLayoutContent>
      </Page>
    </Layout>
  )
}

const AuditTrailPage = () => {
  const { documentId } = useParams()

  if (!documentId) {
    return null
  }

  return (
    <App>
      <AuditTrail documentHash={documentId} />
    </App>
  )
}

export default AuditTrailPage
