import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../uitls/fetch'

export type SignerType = {
  uniqId: number
  hash: string
  name: string
  email: string
  document_status: {
    status: string
    side_text: string
  }
  can_send_reminder: boolean
  can_be_removed: boolean
  document_signed_timestamp: number | string
  attachments: Array<string>
  decline_reason: string
  bounce_type: string | null
  bounce_description: string | null
  bounce_diagnose: string | null
  signing_url: string
}

export type RecepientType = {
  entry_id: number
  name: string
  email: string
  is_deleted: number
  role?: string
}

export type DocumentFileType = {
  entry_id: number
  document_hash: string
  file_location_id: string
  file_id: string
  file_name: string
  page_count: number
  order_num: number
  status: 1 | 0
  is_deleted: 1 | 0
  user_file: number
}

export type DocumentDetail = {
  document_title: string
  documentFileName: string
  page_count: number
  created_on: string
  document_status: string
  sent_by_user: {
    first_name: string
    last_name: string
    email_address: string
  }
  is_external: boolean
  is_embedded_template: number
  signers: Array<SignerType>
  recepients?: Array<RecepientType>
  expires_on: string
  document_files: Array<DocumentFileType>
}

type ResponseError = {
  error: {
    message: string
  }
}

const fetchDocumenDetails = async (
  documentHash: string,
): Promise<DocumentDetail | ResponseError> => {
  const url = `/bff/documents/detail?document_hash=${documentHash}`

  try {
    const res = await fetch(url)

    if (res.status === 500) {
      throw new Error('Something went wrong')
    }

    const result: DocumentDetail | ResponseError = await res.json()

    if ('error' in result) {
      throw new Error(result.error.message)
    }

    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

type SignerMutationBodyType = {
  documentHash: string
  signerUniqId?: number
}

const sendReminderCall = async ({
  documentHash,
  signerUniqId,
}: SignerMutationBodyType): Promise<any> => {
  const url = `/php/advanced_ajax_handler.php?&type=eversign_send_reminder`
  const data = {
    document_hash: documentHash,
    ...(signerUniqId && { signer_unique_id: signerUniqId }),
  }

  const formData = new FormData()
  formData.append('postArray', JSON.stringify(data))

  try {
    const res = await fetch(url, {
      method: 'POST',
      //@ts-ignore
      body: new URLSearchParams(formData),
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    const result: any = await res.json()

    if (!result.success) {
      throw new Error(
        'An error occurred. Please try again or contact support. (Error Reference: Signer Can Not Be Reminded)',
      )
    }

    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

const sendDeleteCall = async ({
  documentHash,
  signerUniqId,
}: SignerMutationBodyType): Promise<any> => {
  const url = `/php/advanced_ajax_handler.php?&type=eversign_remove_signer`
  const data = { document_hash: documentHash, signer_unique_id: signerUniqId }
  const formData = new FormData()
  formData.append('postArray', JSON.stringify(data))

  try {
    const res = await fetch(url, {
      method: 'POST',
      //@ts-ignore
      body: new URLSearchParams(formData),
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    const result: any = await res.json()
    console.log('result: ', result)
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export type AttachmentFileType = {
  file_name: string
  file_size: number
  file_url: string
  file_id: string
}

const sendGetAttachmentsCall = async ({
  attachmentIds,
}: {
  attachmentIds: Array<string>
}): Promise<any> => {
  const url = '/bff/documents/detail/attachments'

  try {
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(attachmentIds),
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    return await res.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

const sendGetDownloadUrl = async ({ documentHash }: { documentHash: string }): Promise<any> => {
  const url = `/bff/documents/detail/downloadUrl?document_hash=${documentHash}`

  try {
    const res = await fetch(url, {
      method: 'GET',
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    return await res.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

const sendPublicGetDownloadUrl = async ({
  documentHash,
  signerHash,
}: {
  documentHash: string
  signerHash: string
}): Promise<{url: string, is_completed: number}> => {
  const url = `/bff/public/documents/detail/downloadUrl?document_hash=${documentHash}&signer_hash=${signerHash}`

  try {
    const res = await fetch(url, {
      method: 'GET',
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    return await res.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function useGetDocumentDetail({ documentHash }: { documentHash: string }) {
  return useQuery(
    ['fetch-document-detail', documentHash],
    () => fetchDocumenDetails(documentHash),
    {
      keepPreviousData: true,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  )
}

export function useSendReminder() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ documentHash, signerUniqId }: SignerMutationBodyType) =>
      sendReminderCall({ documentHash, signerUniqId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetch-document-detail'])
      },
    },
  )
}

export function useDeleteSigner({ documentHash, signerUniqId }: SignerMutationBodyType) {
  const queryClient = useQueryClient()

  return useMutation(() => sendDeleteCall({ documentHash, signerUniqId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetch-document-detail'])
    },
  })
}

export function useGetAttachmentsList({ attachmentIds }: { attachmentIds: Array<string> }) {
  return useMutation(() => sendGetAttachmentsCall({ attachmentIds }), {
    onSuccess: () => {},
  })
}

export function useGetDownloadUrl({ documentHash }: { documentHash: string }) {
  return useQuery(
    ['fetch-document-download-url', documentHash],
    () => sendGetDownloadUrl({ documentHash: documentHash }),
    {
      keepPreviousData: true,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  )
}

export function usePublicGetDownloadUrl({
  documentHash,
  signerHash,
}: {
  documentHash: string
  signerHash: string
}) {
  return useQuery(
    ['fetch-document-download-url', documentHash],
    () => sendPublicGetDownloadUrl({ documentHash: documentHash, signerHash: signerHash }),
    {
      keepPreviousData: true,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  )
}
