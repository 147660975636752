import {
    Link,
    MenuItem,
  } from '@chakra-ui/react'

export const HeaderDropdownMenuItem = ({ children, testId, ...props }) => (
    <MenuItem
      as={Link}
      href={props.href}
      data-testid={testId}
      _active={{
        bg: 'gray.50',
        outline: 'none',
        shadow: 'none',
      }}
      _focus={{
        bg: 'gray.50',
        outline: 'none',
        shadow: 'none',
      }}
      _hover={{
        bg: 'gray.60',
        outline: 'none',
        shadow: 'none',
      }}
    >
      {children}
    </MenuItem>
  )

  export const HeaderDropdownClickItem = ({ children, onClick, testId, ...props }) => (
    <MenuItem
      as="div"
      onClick={onClick}
      data-testid={testId}
      _active={{
        bg: 'gray.50',
        outline: 'none',
        shadow: 'none',
      }}
      _focus={{
        bg: 'gray.50',
        outline: 'none',
        shadow: 'none',
      }}
      _hover={{
        bg: 'gray.60',
        outline: 'none',
        shadow: 'none',
      }}
      {...props}
    >
      {children}
    </MenuItem>
  );
  