import {
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useGetBusinessSettings } from '../../api/legacy/use-business-settings'
import {
  EvrCaretDown,
  EvrCaretUp,
  EvrCode,
  EvrCreditCard,
  EvrCube,
  EvrFeather,
  EvrHammer,
  EvrLock,
  EvrPowerSwitch,
  EvrStar,
  Flex,
  Text,
} from '../../primitives'
import Image from '../../primitives/Image/Image'
import { useFetchSidebarData } from '../../api/use-fetch-sidebar'

const GRAY_50 = 'var(--chakra-colors-gray-50)'
const GRAY_60 = 'var(--chakra-colors-gray-60)'

export const DropdownMenu = ({ isOpen, onToggle }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onToggle()
  }

  const sidebarData = useFetchSidebarData()
  const businessSettings = useGetBusinessSettings()

  const customIcon = businessSettings.data?.custom_icon
  const businessName = sidebarData.data?.businessName
  const userName = sidebarData.data?.loggedInAsUsername

  // when this feature is ran from "react-entry.php" with react router as provider,
  // we need to use ReactRouterLink in mobile menu for User
  // however when this component is run as old non routed feature,
  // there wont be Router Provider wrapping it, so it will throw error when using ReactRouterLink
  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  return (
    <Menu isOpen={isOpen}>
      <Skeleton
        isLoaded={!!businessName && !!customIcon}
        height="30px"
        startColor={'#E8EFF6'}
        endColor={'#ced4da'}
      >
        <MenuButton
          onClick={handleClick}
          width="100%"
          height="40px"
          borderRadius="0px"
          bg="navy.100"
          color="white"
          data-testid="business-actions-dropdown"
        >
          <Flex alignItems="center" justifyContent="center">
            <Image
              src={customIcon}
              width="30px"
              ml={{
                base: '20px',
                md: '0px',
                lg: '0px',
                xl: '0px',
              }}
            />
            <Text
              fontSize={'sm'}
              fontWeight="medium"
              mx="10px !important"
              display={{
                base: 'inline',
                md: 'none',
                lg: 'none',
                xl: 'inline',
              }}
            >
              {businessName}
            </Text>
            {isOpen ? (
              <EvrCaretUp
                mt="6px"
                display={{
                  base: 'inline',
                  md: 'none !important',
                  xl: 'inline !important',
                }}
              />
            ) : (
              <EvrCaretDown
                mt="6px"
                display={{
                  base: 'inline',
                  md: 'none !important',
                  xl: 'inline !important',
                }}
              />
            )}
          </Flex>
        </MenuButton>
      </Skeleton>
      <MenuList
        borderRadius={'0px'}
        fontSize={'14px'}
        color="indigo.100"
        py="0px"
        fontFamily={'facto'}
        fontWeight={400}
        data-testid="dropdown-business-actions-list-container"
        minWidth="180px"
        width={['100VW', '210px']}
        mt={['0px', '6px']}
      >
        <MenuItem
          as="a"
          href="/select"
          color="indigo.100"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Exit Business
        </MenuItem>
        <MenuItem
          color="navy.100"
          borderBottom={'1px solid'}
          borderColor="brand.700"
          bgColor="gray.50"
          height="37px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          <Text as="span" fontWeight={500} fontSize={'sm'} noOfLines={1} lineHeight="normal">
            Logged in as:{' '}
            <Text
              as="span"
              fontWeight={400}
              color={'brand.800'}
              fontFamily="facto"
              fontSize={'sm'}
              data-testid={'username'}
            >
              {userName}
            </Text>
          </Text>
        </MenuItem>
        {reactRouterEntryNode ? (
          // eslint-disable-next-line react/jsx-no-undef
          <ChakraLink
            as={ReactRouterLink}
            to="/user"
            href="/user"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
            display="flex"
            alignItems={'center'}
          >
            <EvrHammer color="brand.800" h={5} w={5} ml="3" mr="3" />
            <Text as="span" fontSize={'14px'}>
              User Settings
            </Text>
          </ChakraLink>
        ) : (
          <MenuItem
            icon={<EvrHammer color="brand.800" h={5} w={5} />}
            as="a"
            href="/user"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
          >
            User Settings
          </MenuItem>
        )}
        {reactRouterEntryNode ? (
          <ChakraLink
            as={ReactRouterLink}
            to="/signature"
            href="/signature"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
            display="flex"
            alignItems={'center'}
          >
            <EvrFeather color="brand.800" h={5} w={5} ml="3" mr="3" />
            <Text as="span" fontSize={'14px'}>
              Signatures
            </Text>
          </ChakraLink>
        ) : (
          <MenuItem
            icon={<EvrFeather color="brand.800" h={5} w={5} />}
            as="a"
            href="/signature"
            bgColor={`${GRAY_50} !important`}
            height="40px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
          >
            Signatures
          </MenuItem>
        )}
        <MenuItem
          icon={<EvrLock color="brand.800" h={5} w={5} />}
          as="a"
          href="/account/edit"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Account
        </MenuItem>
        <MenuItem
          icon={<EvrStar color="brand.800" h={5} w={5} />}
          as="a"
          href="/subscription"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Subscription
        </MenuItem>
        <MenuItem
          icon={<EvrCreditCard color="brand.800" h={5} w={5} />}
          as="a"
          href="/billing"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Billing
        </MenuItem>
        <MenuItem
          icon={<EvrCode color="brand.800" h={5} w={5} />}
          as="a"
          href="/developer"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Developer
        </MenuItem>
        <MenuItem
          icon={<EvrCube color="brand.800" h={5} w={5} />}
          as="a"
          href="/authorizations"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Connected Apps
        </MenuItem>
        <MenuItem
          icon={<EvrPowerSwitch color="brand.800" h={5} w={5} />}
          as="a"
          href="/logout"
          bgColor={`${GRAY_50} !important`}
          height="40px"
          _hover={{
            bg: `${GRAY_60} !important`,
            outline: 'none',
            shadow: 'none',
          }}
        >
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
