import { Skeleton as Base, SkeletonProps as Props } from '@chakra-ui/react'

export interface SkeletonProps extends Props {
  testId?: string
}

export function Skeleton({ ...props }: SkeletonProps) {
  return <Base {...props} />
}

export default Skeleton
