import { useCallback, useState } from 'react'
import { getAppConfig } from '../Provider/App.Provider'
export interface UseApiReturn<T, E> {
  error: E | undefined
  data: T | undefined
  loading: boolean
}

export interface ErrorCode {
  code: number
  type: string
}

interface CustomeRequestInit extends RequestInit {
  endpoint?: string
}

const DEFAULT_STATE = {
  error: undefined,
  loading: false,
  data: undefined,
}

function getUrl(
  input: RequestInfo,
  init: CustomeRequestInit | undefined,
  BASE_URL: string,
  EVERSIGN_API_URL: string | false,
  legacy: boolean,
  withError: boolean,
) {
  let ENDPOINT = legacy ? `https://${BASE_URL}` : EVERSIGN_API_URL
  if (init?.endpoint) {
    ENDPOINT = init.endpoint
  }
  let url = `${ENDPOINT}${input}`
  if (withError) {
    const q = new URLSearchParams(url)
    if (Array.from(q.keys()).length > 0) {
      url = `${url}&withError=true`
    }
  }
  return url
}

function getHeader(
  init: CustomeRequestInit | undefined,
  variables: CustomeRequestInit | undefined,
  legacy: boolean,
): RequestInit['headers'] {
  const headers: RequestInit['headers'] = {}
  try {
    if (legacy) {
      const csrfToken =
        document?.querySelector("meta[name='Csrf-Token']")?.getAttribute('content') ??
        'no token given'
      headers['Csrf-Token'] = csrfToken
    }
  } catch (error) {
    console.error(error)
    throw error
  }

  return {
    ...init?.headers,
    ...variables?.headers,
    ...headers,
  }
}

function setSuccessfullState<T, E>(oldState: UseApiReturn<T, E>, data: unknown) {
  return {
    ...oldState,
    error: undefined,
    loading: false,
    data: data as T,
  }
}
function setErrorState<E>(data: unknown) {
  if (!data) {
    throw new Error('No data defined')
  }
  let error = data
  //@ts-ignore
  if ('error' in data) {
    //@ts-ignore
    error = data.error
  }
  return {
    ...DEFAULT_STATE,
    error: error as E,
  }
}

/**
 *
 * @param input
 * mostly http url
 * @param init
 * Request parameters
 * @param legacy
 * is used for old advanced ajax request endpoint which request an Csrf-Token token
 * @returns T
 */
export function useMutation<T, E extends unknown>(
  input: RequestInfo,
  init?: CustomeRequestInit | undefined,
  legacy = false,
) {
  const [state, setState] = useState(DEFAULT_STATE as UseApiReturn<T, E>)

  const { BASE_URL, EVERSIGN_API_URL } = getAppConfig()

  const run = useCallback(
    // vscode dont infer the type
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    async (variables: CustomeRequestInit | undefined = init, withError: boolean = false) => {
      setState({
        ...state,
        loading: true,
      })

      const url = getUrl(input, init, BASE_URL, EVERSIGN_API_URL, legacy, withError)

      try {
        const response = await fetch(url, {
          ...init,
          ...variables,
          headers: getHeader(init, variables, legacy),
        })
        // const a = clone(response)
        const data = await response.json()

        // const b = await determinNewState(data)

        // handle normal error response
        if (!response.ok) {
          const newState = {
            ...state,
            loading: false,
            error: (data?.error ?? data) as E,
          }
          setState(newState)
          return newState
        }

        // in legacy php ui success is everytime present
        if (legacy) {
          if (data.success) {
            const newState = setSuccessfullState(state, data)
            setState(newState)
            return newState
          }
          const newState = setErrorState<E>(data)
          setState(newState)
          return newState
        }
        // in public api success is only present when an error occurred
        if (data && 'success' in data) {
          const newState = {
            ...state,
            error: data as E,
          }
          setState(newState)
          return newState
        }

        const newState = setSuccessfullState(state, data)
        setState(newState)
        return newState
      } catch (error: unknown) {
        const newState = setErrorState<E>(error)
        setState(newState)
        return newState
      }
    },
    [init, state, legacy, BASE_URL, EVERSIGN_API_URL, input],
  )

  const reset = useCallback(() => {
    setState(DEFAULT_STATE as UseApiReturn<T, E>)
  }, [])

  return {
    ...state,
    run,
    reset,
  }
}
