const fetchWrapper = (apiEndpoint: RequestInfo, options: RequestInit = {}) => {
  const csrfTokenElement = document.querySelector("meta[name='Csrf-Token']")
  const csrfToken = csrfTokenElement ? csrfTokenElement.getAttribute('content') : 'no token given'

  return fetch(apiEndpoint, {
    ...options,
    headers: {
      ...options.headers,
      //@ts-ignore
      'Csrf-Token': csrfToken,
    },
  })
}

export default fetchWrapper
