import { Box, Link as ChakraLink, Link, Stack, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useGetBusinessSettings } from '../../api/legacy/use-business-settings'
import {
  EvrApartment,
  EvrArrowRight,
  EvrCompare,
  EvrCopy,
  EvrGroupWork,
  EvrLifeBuoy,
  EvrPencil4,
  EvrPieChart,
  EvrPlus,
  EvrSettings,
  EvrStar,
  EvrTrash2,
  EvrUsers,
  Flex,
  Text,
} from '../../primitives'
import Image from '../../primitives/Image/Image'
import Skeleton from '../../primitives/Skeleton/Skeleton'
import { Tooltip } from '../../primitives/Tooltip/Tooltip'
import { DropdownMenu } from '../PageNotFound/Dropdown'
import { useFetchSidebarData } from '../../api/use-fetch-sidebar'

export const DesktopMenu = () => {
  const [isBeta, setQuery] = useState('')
  const { isOpen, onToggle } = useDisclosure()
  const businessSettings = useGetBusinessSettings()
  const sidebarData = useFetchSidebarData()
  const [isHovered, setIsHovered] = useState<string | false>(false)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setQuery(params.get('test') || '')
  }, [])

  const customLogo = businessSettings.data?.custom_logo
  const defaultTab = businessSettings.data?.document_list_default_tab
  const documentsUrl = defaultTab ? `/documents?filterQuery=${defaultTab}` : '/documents'
  const currentPath = window.location.pathname
  const showUpgradeButton = sidebarData.data?.showUpgradeButton

  const handleMouseEnter = (element: string) => {
    setIsHovered(element)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  // when this feature is ran from "react-entry.php" with react router as provider,
  // we need to use ReactRouterLink in mobile menu for User
  // however when this component is run as old non routed feature,
  // there wont be Router Provider wrapping it, so it will throw error when using ReactRouterLink
  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  return (
    <Box
      position="fixed"
      left="0"
      top="0"
      w={{
        base: '75px',
        xl: '210px',
      }}
      h="100vh"
      bg="navy.100"
      zIndex={1}
    >
      <Stack m="12px 0px 20px" align="center" spacing="10">
        <DropdownMenu isOpen={isOpen} onToggle={onToggle} />

        <Skeleton
          display={{ base: 'none', xl: 'block' }}
          isLoaded={!!customLogo || sidebarData.isLoading}
          width="150px"
          startColor="#E8EFF6"
          endColor="#ced4da"
          borderRadius="4px"
          margin="0 auto"
        >
          <Image
            src={customLogo}
            padding="5px"
            alt="Xodosign Logo"
            height="43px"
            margin="0 auto"
            data-testid="skeleton-loader"
          />
        </Skeleton>
      </Stack>

      {showUpgradeButton && (
        <>
          <Link
            href="/subscription"
            variant="primary"
            display={{
              base: 'none',
              xl: 'flex',
            }}
            justifyContent="center"
            alignItems="center"
            bg="#00EB9B !important"
            mx="3"
            mb="6"
            data-testid="sidebar-upgrade-subscription-btn"
            fontWeight={500}
            borderBottom="2px solid rgba(0, 0, 0, .2)"
            fontSize="sm"
            height="38px"
            lineHeight="38px"
            backgroundSize="300% !important"
            _hover={{
              backgroundImage:
                'linear-gradient(90deg,#99f7d7,#99f7d7 35%,#00EB9B 65%,#00EB9B) !important',
              backgroundPosition: '50% !important',
            }}
          >
            Upgrade!
          </Link>
          <Link
            href="/subscription"
            display={{
              base: 'flex',
              xl: 'none',
            }}
            justifyContent="center"
            alignItems="center"
            _focus={{ boxShadow: 'none' }}
          >
            <EvrStar w="20px" h="20px" color="white" m="30px auto" />
          </Link>
        </>
      )}

      <Flex color="gray.50" direction="column" gap={0.3} height="100%">
        <Text
          data-page-identifier="dashboard"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          justifyContent={{ base: 'center', xl: 'flex-start' }}
          h="46px"
          w={{
            base: '75px',
            xl: '210px',
          }}
          bg={currentPath === '/dashboard' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/dashboard"
              href="/dashboard"
              display="flex"
              alignItems={'center'}
              data-testid="dashboard-link"
            >
              <EvrPieChart w={'20px'} h={'20px'} mr={'8px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Dashboard
              </Box>
            </ChakraLink>
          ) : (
            <Link
              href="/dashboard"
              display="flex"
              _focus={{ boxShadow: 'none' }}
              data-testid="dashboard-link"
            >
              <EvrPieChart w={'20px'} h={'20px'} mr={'8px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Dashboard
              </Box>
            </Link>
          )}
        </Text>

        {isBeta && (
          <Text
            data-page-identifier="tools"
            fontSize="sm"
            pl={'13px'}
            display="flex"
            alignItems="center"
            justifyContent={{ base: 'center', xl: 'flex-start' }}
            h="46px"
            w={{
              base: '75px',
              xl: '210px',
            }}
            bg={currentPath === '/tools' ? 'navy.80' : 'transparent'}
            _hover={{ bg: 'navy.100' }}
          >
            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/tools"
                href="/tools"
                display="flex"
                alignItems={'center'}
                data-testid="tools-link"
              >
                <EvrPencil4 w={'18px'} h={'18px'} mr={'8px'} />
                <Box
                  as="span"
                  display={{
                    base: 'none',
                    xl: 'inline',
                  }}
                >
                  Edit a Document
                </Box>
              </ChakraLink>
            ) : (
              <Link
                href="/tools"
                display="flex"
                _focus={{ boxShadow: 'none' }}
                data-testid="tools-link"
              >
                <EvrPencil4 w={'18px'} h={'18px'} mr={'8px'} />
                <Box
                  as="span"
                  display={{
                    base: 'none',
                    xl: 'inline',
                  }}
                >
                  Edit a Document
                </Box>
              </Link>
            )}
          </Text>
        )}

        <Text
          data-page-identifier="documents"
          h="46px"
          w={{
            base: '120px',
            xl: '210px',
          }}
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="13px"
          onMouseEnter={() => handleMouseEnter('documents')}
          onMouseLeave={handleMouseLeave}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to={documentsUrl}
              href={documentsUrl}
              display="flex"
              alignItems="center"
              data-testid="documents-link"
            >
              <EvrCopy w="20px" h="20px" mr="8px" ml={{ base: '15px', xl: '0px' }} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Documents
              </Box>
            </ChakraLink>
          ) : (
            <Link
              data-testid="documents-link"
              href={documentsUrl}
              display="flex"
              _focus={{ boxShadow: 'none' }}
            >
              <EvrCopy w="20px" h="20px" mr="10px" ml={{ base: '15px', xl: '0px' }} />
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Documents
              </Box>
            </Link>
          )}

          {isHovered === 'documents' && (
            <Tooltip
              label={'New Document'}
              aria-label={'New Document'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              <Link href="/documents/new" _focus={{ boxShadow: 'none' }}>
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <EvrPlus maxW={'12px'} maxH={'12px'} />
                </Flex>
              </Link>
            </Tooltip>
          )}
        </Text>

        <Text
          data-page-identifier="templates"
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="13px"
          w={{
            base: '120px',
            xl: '210px',
          }}
          height="46px"
          onMouseEnter={() => handleMouseEnter('templates')}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            data-testid="templates-link"
            href="/templates"
            display="flex"
            _focus={{ boxShadow: 'none' }}
          >
            <EvrCompare w={'20px'} h={'20px'} mr={'10px'} ml={{ base: '15px', xl: '0px' }} />
            <Box
              as="span"
              display={{
                base: 'none',
                xl: 'inline',
              }}
            >
              Templates
            </Box>
          </Link>
          {isHovered === 'templates' && (
            <Tooltip
              label={'New Template'}
              aria-label={'New Template'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              <Link href="/templates/new" _focus={{ boxShadow: 'none' }}>
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <EvrPlus w={'12px'} h={'12px'} />
                </Flex>
              </Link>
            </Tooltip>
          )}
        </Text>

        {isBeta && (
          <Text
            data-page-identifier="contacts"
            fontSize="sm"
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
            pl={'13px'}
            w={{
              base: '120px',
              xl: '210px',
            }}
            height="46px"
            onMouseEnter={() => handleMouseEnter('contacts')}
            onMouseLeave={handleMouseLeave}
          >
            <ChakraLink
              as={ReactRouterLink}
              to={'/import'}
              href={'/import'}
              display="flex"
              alignItems="center"
              data-testid="documents-link"
            >
              <EvrApartment w={'20px'} h={'20px'} mr={'10px'} ml={{ base: '15px', xl: '0px' }} />
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Import from Docusign
              </Box>
            </ChakraLink>
          </Text>
        )}

        <Text
          data-page-identifier="contacts"
          fontSize="sm"
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          pl={'13px'}
          w={{
            base: '120px',
            xl: '210px',
          }}
          height="46px"
          onMouseEnter={() => handleMouseEnter('contacts')}
          onMouseLeave={handleMouseLeave}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/contacts"
              href="/contacts"
              display="flex"
              alignItems={'center'}
              _focus={{ boxShadow: 'none' }}
              data-testid="contacts-link"
            >
              <EvrGroupWork w={'20px'} h={'20px'} mr={'10px'} ml={{ base: '15px', xl: '0px' }} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Contacts
              </Box>
            </ChakraLink>
          ) : (
            <Link
              data-testid="contacts-link"
              href="/contacts"
              display="flex"
              _focus={{ boxShadow: 'none' }}
            >
              <EvrGroupWork w={'20px'} h={'20px'} mr={'10px'} ml={{ base: '15px', xl: '0px' }} />
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Contacts
              </Box>
            </Link>
          )}

          {isHovered === 'contacts' && (
            <Tooltip
              label={'New Contact'}
              aria-label={'New Contact'}
              placement="bottom"
              bg="brand.200"
              color="brand.900"
              fontWeight={'normal'}
              fontSize="xs"
              hasArrow={false}
              borderRadius="0px"
            >
              {reactRouterEntryNode ? (
                <Flex
                  as="span"
                  alignItems="center"
                  justifyContent="center"
                  bg="navy.90"
                  width="46px"
                  height="46px"
                  _hover={{ bg: 'navy.80' }}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/contacts/new"
                    href="/contacts/new"
                    _focus={{ boxShadow: 'none' }}
                  >
                    <EvrPlus w={'12px'} h={'12px'} />
                  </ChakraLink>
                </Flex>
              ) : (
                <Link href="/contacts/new" _focus={{ boxShadow: 'none' }}>
                  <Flex
                    as="span"
                    alignItems="center"
                    justifyContent="center"
                    bg="navy.90"
                    width="46px"
                    height="46px"
                    _hover={{ bg: 'navy.80' }}
                  >
                    <EvrPlus w={'12px'} h={'12px'} />
                  </Flex>
                </Link>
              )}
            </Tooltip>
          )}
        </Text>

        <Text
          data-page-identifier="team"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w={{
            base: '75px',
            xl: '210px',
          }}
          bg={currentPath === '/team' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/team"
              href="/team"
              display="flex"
              alignItems={'center'}
              data-page-identifier="team"
              data-testid="team-link"
            >
              <EvrUsers w={'20px'} h={'20px'} mr={'10px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Team
              </Box>
            </ChakraLink>
          ) : (
            <Link
              data-testid="team-link"
              href="/team"
              display="flex"
              _focus={{ boxShadow: 'none' }}
            >
              <EvrUsers w={'20px'} h={'20px'} mr={'10px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Team
              </Box>
            </Link>
          )}
        </Text>

        <Text
          data-page-identifier="trash"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w={{
            base: '75px',
            xl: '210px',
          }}
          bg={currentPath === '/trash' ? 'navy.80' : 'transparent'}
          _hover={{ bg: 'navy.100' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
        >
          {reactRouterEntryNode ? (
            <ChakraLink
              as={ReactRouterLink}
              to="/trash"
              href="/trash"
              display="flex"
              alignItems={'center'}
            >
              <EvrTrash2 w={'20px'} h={'20px'} mr={'10px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Trash
              </Box>
            </ChakraLink>
          ) : (
            <Link
              data-testid="trash-link"
              href="/trash"
              display="flex"
              _focus={{ boxShadow: 'none' }}
            >
              <EvrTrash2 w={'20px'} h={'20px'} mr={'10px'} />{' '}
              <Box
                as="span"
                display={{
                  base: 'none',
                  xl: 'inline',
                }}
              >
                Trash
              </Box>
            </Link>
          )}
        </Text>

        <Text
          data-page-identifier="settings"
          fontSize="sm"
          pl={'13px'}
          display="flex"
          alignItems="center"
          h="46px"
          w={{
            base: '75px',
            xl: '210px',
          }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
        >
          <Link
            data-testid="business-settings-link"
            href="/settings"
            display="flex"
            _focus={{ boxShadow: 'none' }}
          >
            <EvrSettings w={'20px'} h={'20px'} mr={'10px'} />{' '}
            <Box
              as="span"
              display={{
                base: 'none',
                xl: 'inline',
              }}
            >
              Business Settings
            </Box>
          </Link>
        </Text>

        <Text
          fontSize="sm"
          position="fixed"
          bottom={1}
          display="flex"
          h="46px"
          w={{
            base: '75px',
            xl: '210px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Link
            href="https://help.eversign.com/"
            target="_blank"
            display="flex"
            _focus={{ boxShadow: 'none' }}
          >
            <EvrLifeBuoy w={'20px'} h={'20px'} mr={'10px'} />
            <Box
              as="span"
              display={{
                base: 'none',
                xl: 'inline',
              }}
            >
              Help Center
            </Box>
          </Link>
        </Text>
      </Flex>
    </Box>
  )
}

export default DesktopMenu
