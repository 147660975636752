import { Grid, GridItem, Hide } from '@chakra-ui/react'
import EmailConfirmationBanner from '../../Features/Confirmation Banner/EmailConfirmationBanner'
import NotificationBanner from '../../Features/NotificationBanner/NotificationBanner.lazy'
import { useUserEmailStatus } from '../../Features/UserSettings/use-user-settings'
import DesktopMenu from '../SidebarNav/DesktopMenu'
import MobileMenu from '../SidebarNav/MobileMenu'

export default function LegacyLayout({ children }) {
  const userData = useUserEmailStatus()
  const emailConfirmedStatus = userData.data?.email_confirmed_status

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(1, 1fr)"
      id="legacy-layout-container"
    >
      <GridItem rowSpan={2} colSpan={1}>
        <Hide below="md">
          <DesktopMenu />
        </Hide>
        <Hide above="md">
          <MobileMenu />
        </Hide>
      </GridItem>
      {emailConfirmedStatus === 0 && (
        <GridItem colSpan={1}>
          <EmailConfirmationBanner />
        </GridItem>
      )}
      <GridItem colSpan={1}>
        <NotificationBanner />
      </GridItem>
      <GridItem colSpan={1}>{children}</GridItem>
    </Grid>
  )
}
