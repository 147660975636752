import { Grid, GridItem } from '@chakra-ui/react'
import EmailConfirmationBanner from '../../Features/Confirmation Banner/EmailConfirmationBanner'
import NotificationBanner from '../../Features/NotificationBanner/NotificationBanner.lazy'
import { useUserEmailStatus } from '../../Features/UserSettings/use-user-settings'
import DesktopMenu from '../SidebarNav/DesktopMenu'
import MobileMenu from '../SidebarNav/MobileMenu'

export default function Layout({ children }) {
  const userData = useUserEmailStatus()
  const emailConfirmedStatus = userData.data?.email_confirmed_status

  return (
    <Grid
      templateColumns={{
        base: '1fr', // On the smallest screens, only one column
        md: '75px 1fr', // On larger screens (from lg) use a narrow sidebar
        xl: '210px 1fr', // On extra-large screens, use a wider sidebar
      }}
      templateRows="auto 1fr 1fr"
      minHeight="100vh"
      width="100%"
    >
      <GridItem
        width={{ base: '0px', md: '75px', xl: '210px' }}
        display={{ base: 'none', md: 'block', xl: 'block' }}
      >
        <DesktopMenu />
      </GridItem>


        <GridItem display={{ base: 'block', md: 'none' }}>
          <MobileMenu />
        </GridItem>


      <GridItem colSpan={{ base: 2, md: 1 }}>
        {emailConfirmedStatus === 0 && (
          <GridItem colSpan={2}>
            <EmailConfirmationBanner />
          </GridItem>
        )}

        <GridItem colSpan={2}>
          <NotificationBanner />
        </GridItem>

        <GridItem colSpan={2}>{children}</GridItem>
      </GridItem>
    </Grid>
  )
}
