import { useQuery } from '@tanstack/react-query'

const fetchAuditTrail = async ({
  documentHash,
}: {
  documentHash: string
}): Promise<{ url: string; is_completed: number }> => {
  const url = `/bff/documents/audit?document_hash=${documentHash}`

  try {
    const res = await fetch(url, {
      method: 'GET',
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    return await res.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function useGetAuditTrailDocument({ documentHash }: { documentHash: string }) {
  return useQuery(
    ['fetch-document-at-url', documentHash],
    () => fetchAuditTrail({ documentHash: documentHash }),
    {
      keepPreviousData: true,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  )
}
