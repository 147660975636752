import { RefObject, useEffect, useRef } from 'react'
import WebViewer from '@pdftron/webviewer'

export const Webviewer = ({
  pdfURL,
  hasSignaturePanel = true,
}: {
  pdfURL: string
  hasSignaturePanel?: boolean
}) => {
  const viewer: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  const wvConfig = window.EVERSIGN_CONFIG
  const wwlk = wvConfig && window.EVERSIGN_CONFIG.WV_KEY
  // const path = `${wvConfig.ASSETS_URL}/dist/webviewer`;
  const path = `/dist/webviewer`

  let decodedWWLK = ''
  if (wvConfig && wvConfig.WV_KEY) {
    decodedWWLK = atob(wwlk)
  }

  useEffect(() => {
    if (!viewer.current || viewer.current.children.length > 0) return

    WebViewer.WebComponent(
      {
        path: path,
        licenseKey: decodedWWLK,
        disableLogs: false,
        enableAnnotations: false,
        isReadOnly: true,
        fullAPI: true,
      },
      viewer.current,
    ).then((instance) => {
      const { UI, Core } = instance
      const { documentViewer } = Core
      documentViewer.loadDocument(pdfURL)

      UI.disableElements([
        'menuButton',
        'view-controls-toggle-button',
        'annotationEditToolButton',
        'searchPanelToggle',
        'outlinesPanel-tabPanel',
        'bookmarksPanel-tabPanel',
        'layersPanel-tabPanel',
        'fileAttachmentPanel-tabPanel',
        'documentControl',
        'thumbnailControl',
      ])

      const isPhone = window.innerWidth < 768

      instance.Core.documentViewer.addEventListener('documentLoaded', () => {
        // displaying thumbnails in left side bar on desktop devices
        if (!isPhone) {
          const startupElements = hasSignaturePanel ? 'signaturePanel' : 'thumbnailsPanel'

          UI.openElements(['tabPanel'])
          UI.setActiveLeftPanel(startupElements)
        }

        // do NOT display signature panel on template detail pages
        if (!hasSignaturePanel) {
          UI.disableElements(['signaturePanel', 'signaturePanel-tabPanel'])
        }

        try {
          UI.VerificationOptions.addTrustedCertificates([
            '/certificates/1415494347_Sectigo_RSA_Document_Signing_CA.crt',
          ])
        } catch (err) {
          console.log('could not load or parse trusted list ', err)
        }
      })
    })
  }, [decodedWWLK, hasSignaturePanel, path, pdfURL])

  return (
    <div className="board document" style={{ margin: '20px 0 0 0' }}>
      <div
        className="webviewer"
        ref={viewer as RefObject<HTMLDivElement>}
        style={{ height: '100vh' }}
      ></div>
    </div>
  )
}
