import { useQuery } from '@tanstack/react-query'

type ShowUpgradeResponse = {
  success: boolean
  showUpgradeButton?: boolean
  businessName?: string
  loggedInAsUsername?: string
}

const fetchSidebarData = async (): Promise<ShowUpgradeResponse> => {
  try {
    const res = await fetch('/bff/user/sidebar')

    if (!res.ok) {
      throw new Error('could not fetch sidebarData')
    }

    return await res.json()
  } catch (err) {
    return {
      success: false,
    }
  }
}

export function useFetchSidebarData() {
  return useQuery(['fetch-sidebar-user-data'], () => fetchSidebarData(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 120, // two hours
    retry: 0,
  })
}
